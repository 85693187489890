import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistrationStepOne.css';
import Footer from '../components/Footer';

function RegistrationStepOne() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const handleNext = () => {
        navigate('/registration-step2');
    };

    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <h1>Hey! Let's get to know you better...</h1>
                <div className="registration-container">
                    <form className="personal-info-form section">
                        <div className="form-group">
                            <input type="text" placeholder="First Name" className="form-input" />
                            <input type="text" placeholder="Middle Name" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="email" placeholder="Email" className="form-input" />
                            <input type="text" placeholder="Phone Number" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="date" placeholder="Date of Birth" className="form-input" />
                            <input type="text" placeholder="Gender" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Last Name" className="form-input" />
                            <input type="text" placeholder="LinkedIn ID" className="form-input" />
                        </div>
                    </form>

                    <div className="profile-photo-upload">
                        <div className="upload-box">
                            <p>Upload profile photo</p>
                            <button className="upload-button">+</button>
                        </div>
                    </div>
                </div>

                <div className="navigation-buttons">
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default RegistrationStepOne;
