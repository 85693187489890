import React from 'react';
import '../styles/Footer.css';

function Footer() {
    return (
        <footer className="footer-section">
            <p>&copy; 2025 getAfterHours - A Product of PathFuze Technologies. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer;
