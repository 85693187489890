import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistrationStepFour.css';
import Footer from '../components/Footer';

function RegistrationStepFour() {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const handleLogout = () => {
        navigate('/login');
    };

    const handleSubmit = () => {
        if (isChecked) {
            navigate('/registration-success');
        } else {
            alert('Please agree to the terms and conditions to proceed.');
        }
    };

    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <h1 className="heading">One last thing.</h1>
                <div className="submit-registration-container">
                    <h2>Submit Registration</h2>
                    <div className="terms-section">
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <label htmlFor="termsCheckbox">
                            By checking this box & clicking Submit, you agree to the AfterHours User Agreement, Privacy
                            Policy, Data Storage Policy, and Cookie Policy.
                        </label>
                    </div>
                    <button className="next-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default RegistrationStepFour;
