// Import React and required dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css';
import Footer from '../components/Footer';

function RegistrationStepFive() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <h1>Welcome to Registration Step 5</h1>
            </main>
            <Footer />
        </div>
    );
}

export default RegistrationStepFive;