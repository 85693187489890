import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import LinksPage from './pages/LinksPage';
import LoginPage from './pages/LoginPage';
import RegistrationStepFive from './pages/RegistrationStepFive';
import RegistrationStepFour from './pages/RegistrationStepFour';
import RegistrationStepOne from './pages/RegistrationStepOne';
import RegistrationStepThree from './pages/RegistrationStepThree';
import RegistrationStepTwo from './pages/RegistrationStepTwo';
import EventsListingPage from './pages/EventsListingPage';


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />

          <Route path="/home" element={<HomePage />} />

          <Route path="/login" element={<LoginPage />} />

          <Route path="/links" element={<LinksPage />} />

          <Route path="/registration-step1" element={<RegistrationStepOne />} />
          <Route path="/registration-step2" element={<RegistrationStepTwo />} />
          <Route path="/registration-step3" element={<RegistrationStepThree />} />
          <Route path="/registration-step4" element={<RegistrationStepFour />} />
          <Route path="/registration-step5" element={<RegistrationStepFive />} />
          <Route path="/events-page" element={<EventsListingPage />} />
        </Routes>
      </Router>
  );
}

export default App;
