// Import React and required dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistrationStepTwo.css';
import Footer from '../components/Footer';

function RegistrationStepTwo() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const handleNext = () => {
        navigate('/registration-step3');
    };

    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <h1>Let’s dig deeper...</h1>
                <div className="registration-container">
                    {/* Education Section */}
                    <div className="section education-section">
                        <h2>Education <button className="add-button">+ Add</button></h2>
                        <div className="form-group">
                            <input type="text" placeholder="College Name" className="form-input" />
                            <input type="text" placeholder="Location" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="date" placeholder="Start Date" className="form-input" />
                            <input type="date" placeholder="End Date" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" id="currently-enrolled" />
                            <label htmlFor="currently-enrolled">I’m currently enrolled here.</label>
                        </div>
                    </div>

                    {/* Work Experience Section */}
                    <div className="section work-experience-section">
                        <h2>Work Experience <button className="add-button">+ Add</button></h2>
                        <div className="form-group">
                            <input type="text" placeholder="Company Name" className="form-input" />
                            <input type="text" placeholder="Location" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="date" placeholder="Start Date" className="form-input" />
                            <input type="date" placeholder="End Date" className="form-input" />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" id="currently-working" />
                            <label htmlFor="currently-working">I currently work here.</label>
                        </div>
                    </div>
                </div>

                <div className="navigation-buttons">
                    <button className="previous-button" onClick={() => navigate('/registration-step1')}>Previous</button>
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            </main>
            <Footer />

        </div>
    );
}

export default RegistrationStepTwo;
