import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistrationStepThree.css';
import Footer from '../components/Footer';

function RegistrationStepThree() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const handleNext = () => {
        navigate('/registration-step4');
    };

    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <h1>Almost there..</h1>
                <div className="registration-container">
                    <div className="section">
                        <h2>
                            Organizations <button className="add-button">+ Add</button>
                        </h2>
                        <div className="form-group">
                            <input type="text" className="form-input" placeholder="Organization Name" />
                            <input type="text" className="form-input" placeholder="Location" />
                        </div>
                        <div className="form-group">
                            <input type="date" className="form-input" placeholder="Start Date" />
                            <input type="date" className="form-input" placeholder="End Date" />
                        </div>
                        <div>
                            <input type="checkbox" id="current-organization" />
                            <label htmlFor="current-organization">I’m currently part of this organization</label>
                        </div>
                    </div>

                    <div className="section">
                        <h2>
                            Work Experience <button className="add-button">+ Add</button>
                        </h2>
                        <div className="form-group">
                            <input type="text" className="form-input" placeholder="Skill Name" />
                        </div>
                        <div className="skills-tags">
                            <span className="skill-tag">Unreal Engine <button className="remove-tag">×</button></span>
                            <span className="skill-tag">C++ <button className="remove-tag">×</button></span>
                            <span className="skill-tag">Adobe Photoshop <button className="remove-tag">×</button></span>
                            <span className="skill-tag">Public Speaking <button className="remove-tag">×</button></span>
                        </div>
                    </div>
                </div>

                <div className="navigation-buttons">
                    <button className="previous-button" onClick={() => navigate('/registration-step1')}>Previous</button>
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default RegistrationStepThree;