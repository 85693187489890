// Import React and required dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LinksPage.css';
import Footer from '../components/Footer';

function LinksPage() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const registrationPage1 = () => {
        navigate('/registration-step1');
    };

    const registrationPage2 = () => {
        navigate('/registration-step2');
    };

    const registrationPage3 = () => {
        navigate('/registration-step3');
    };

    const registrationPage4 = () => {
        navigate('/registration-step4');
    };

    const registrationPage5 = () => {
        navigate('/registration-step5');
    };

    const eventsPage = () => {
        navigate('/events-page');
    };
    
    return (
        <div className="events-page">
            <nav className="top-nav sticky-nav">
                <div className="logo">
                    <img src="/ahs_logo_cream.svg" alt="getAfterHours logo" className="logo-image" />
                    getAfterHours
                </div>
                <button className="login-button" onClick={handleLogout}>
                    Logout
                </button>
            </nav>

            <main className="main-section">
                <button className="registration-page" onClick={registrationPage1}>
                    Registration- Step 1
                </button>
                <button className="registration-page" onClick={registrationPage2}>
                    Registration- Step 2
                </button>
                <button className="registration-page" onClick={registrationPage3}>
                    Registration- Step 3
                </button>
                <button className="registration-page" onClick={registrationPage4}>
                    Registration- Step 4
                </button>
                <button className="registration-page" onClick={registrationPage5}>
                    Registration- Step 5
                </button>
                <button className="registration-page" onClick={eventsPage}>
                    Events page
                </button>
            </main>
            <Footer />
        </div>
    );
}

export default LinksPage;